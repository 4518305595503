var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.login)}}},[_c('v-container',[_c('ValidationProvider',{attrs:{"vid":"class_simple_layout_container","name":"Estilo Layout Simple","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Estilo Layout Simple","hint":"Clase HTML del Layout Simple.","persistent-hint":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.config.class_simple_layout_container),callback:function ($$v) {_vm.$set(_vm.config, "class_simple_layout_container", $$v)},expression:"config.class_simple_layout_container"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"logo_url","name":"Logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Logo","hint":"(Opcional) URL de la imagen del logo.","persistent-hint":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.config.logo_url),callback:function ($$v) {_vm.$set(_vm.config, "logo_url", $$v)},expression:"config.logo_url"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"label_medico","name":"Label médico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Label médico","required":"","error-messages":errors,"success":valid},model:{value:(_vm.config.label_medico),callback:function ($$v) {_vm.$set(_vm.config, "label_medico", $$v)},expression:"config.label_medico"}})]}}],null,true)}),_c('div',{staticClass:"mt-4",attrs:{"align-self-center":""}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return passes(_vm.save)}}},[_vm._v("Guardar")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
    <v-form @submit.prevent="passes(login)">
      <v-container>
        <ValidationProvider vid="class_simple_layout_container" name="Estilo Layout Simple" rules="" v-slot="{ errors, valid }">
          <v-text-field
            filled
            v-model="config.class_simple_layout_container"
            label="Estilo Layout Simple"
            hint="Clase HTML del Layout Simple."
            persistent-hint
            required
            :error-messages="errors"
            :success="valid"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider vid="logo_url" name="Logo" rules="" v-slot="{ errors, valid }">
          <v-text-field
            filled
            v-model="config.logo_url"
            label="Logo"
            hint="(Opcional) URL de la imagen del logo."
            persistent-hint
            required
            :error-messages="errors"
            :success="valid"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider vid="label_medico" name="Label médico" rules="required" v-slot="{ errors, valid }">
          <v-text-field
            filled
            v-model="config.label_medico"
            label="Label médico"
            required
            :error-messages="errors"
            :success="valid"
          ></v-text-field>
        </ValidationProvider>

        <div align-self-center class="mt-4">
          <v-btn type="submit" @click.prevent="passes(save)" color="primary"
              dark :loading="saving">Guardar</v-btn>
        </div>
    </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    saving: false,
    config: {
      class_simple_layout_container: '',
      logo_url: '',
      label_medico: '',
    },
  }),

  methods: {
    get: function () {
      this.loading = true

      this.$http.get('ajustes')
      .then((response) => {
        this.config = response.data
      })
      .catch(() => {
        this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
      })
      .then(() => {
        this.loading = false
      })
    },
    save: function () {
      this.saving = true

      this.$http.put('ajustes', this.config)
      .then((response) => {
        localStorage.setItem('config', JSON.stringify(response.data.data));

        this.$refs.obs.reset()

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          case 422:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

            this.$refs.obs.setErrors(error.response.data.errors);

            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    },
  },

  created: function () {
    this.get()
  }
}
</script>
